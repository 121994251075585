@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply antialiased scroll-smooth;
  }

  body {
    @apply m-0 font-sans text-base;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* Custom scrollbar for modern browsers */
  ::-webkit-scrollbar {
    @apply w-2;
  }

  ::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-gray-400 rounded-full;
  }

  .dark ::-webkit-scrollbar-thumb {
    @apply bg-gray-600;
  }

  /* Focus outline styles */
  *:focus-visible {
    @apply outline-none ring-2 ring-primary-500 ring-offset-2 ring-offset-white;
  }

  .dark *:focus-visible {
    @apply ring-offset-dark-bg;
  }
}

@layer components {
  /* Button styles */
  .btn {
    @apply inline-flex items-center justify-center px-4 py-2 rounded-lg font-medium
    transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2;
  }

  .btn-primary {
    @apply bg-primary-500 text-white hover:bg-primary-600 focus:ring-primary-500;
  }

  .btn-secondary {
    @apply bg-gray-200 text-gray-800 hover:bg-gray-300 focus:ring-gray-500
    dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600;
  }

  /* Card styles */
  .card {
    @apply rounded-xl overflow-hidden shadow-card hover:shadow-card-hover
    transition-all duration-300 bg-white dark:bg-dark-card;
  }

  /* Loading spinner */
  .loading-spinner {
    @apply w-12 h-12 border-4 border-primary-500 border-t-transparent
    rounded-full animate-spin;
  }

  /* Section styles */
  .section {
    @apply py-8 space-y-6;
  }

  .section-title {
    @apply text-2xl font-semibold;
  }

  /* Grid layouts */
  .media-grid {
    @apply grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 sm:gap-6;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Remove all .css files as they're no longer needed */
.animate-fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

.animate-slide-up {
  animation: slideUp 0.5s ease-out;
}

/* Ensure smooth dark mode transitions */
.transition-dark {
  @apply transition-colors duration-200;
}

/* Mobile-first responsive padding */
.container-padding {
  @apply px-4 sm:px-6 lg:px-8;
}

/* Accessibility improvements */
.sr-only {
  @apply absolute w-px h-px p-0 -m-px overflow-hidden whitespace-nowrap border-0;
}
